import React, { useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

interface TogglesProps {
  toggles: Toggle[];
}

interface Toggle {
  id: string;
  text: string;
}

const Toggles: React.FC<TogglesProps> = ({ toggles }) => {
  const [activeToggle, setActiveToggle] = useState<Toggle>(toggles[0]);
  const [openToggles, setOpenToggles] = useState<boolean>(false);

  const handleActiveToggle = (toggle: Toggle) => {
    setActiveToggle(toggle);
    setOpenToggles(false);
  };

  const handleToggleTrigger: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    setOpenToggles(!openToggles);
  };

  return (
    <div className={`toggles ${openToggles ? "toggles--active" : ""}`}>
      <div className="toggles__container">
        <button type="button" className="toggles__trigger" title="Trigger" onClick={handleToggleTrigger}>
          {activeToggle.text}
        </button>
        <div className="toggles__options">
          {toggles &&
            toggles.map((toggle, index) => {
              return (
                <AnchorLink
                  key={index}
                  offset="150"
                  className={`toggles__toggle ${activeToggle.id == toggle.id ? "toggles__toggle--active" : ""}`}
                  href={`#${toggle.id}`}
                  onClick={() => handleActiveToggle(toggle)}
                >
                  {toggle.text}
                </AnchorLink>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Toggles;
