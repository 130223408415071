import React, { useContext } from "react";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";
import { TideItemForReview, TideItemForReviews, TideItemForTestimonialsComponent } from "../../types";
import { getChildItemsWithTypename, getSpecificChildItemWithName, parse } from "../../utils/component";
import Link from "../link";
import Stars from "../stars";

interface ReviewsProps {
  testimonials?: TideItemForTestimonialsComponent;
  specificReviewName?: string;
}

const Reviews: React.FC<ReviewsProps> = ({ testimonials, specificReviewName }) => {
  const { kiyohRating } = useContext<ComponentContextType>(ComponentContext);

  if (!kiyohRating?.content?.general || !testimonials?.content?.general) {
    return null;
  }

  const homePageFolder = getSpecificChildItemWithName("HomePage reviews", kiyohRating) as TideItemForReviews;
  const homePageReviews = homePageFolder
    ? (getChildItemsWithTypename("TideItemForReview", homePageFolder) as TideItemForReview[])
    : ([] as TideItemForReview[]);

  let specificReviews = [] as TideItemForReview[];
  if (specificReviewName) {
    const clubEventFolder = getSpecificChildItemWithName(
      process.env.WEBSITE === "Voetbalreizen" ? "Club reviews" : "Event reviews",
      kiyohRating
    ) as TideItemForReviews;
    specificReviews = (getChildItemsWithTypename("TideItemForReview", clubEventFolder) as TideItemForReview[]).filter((r) =>
      r.name.toLowerCase().startsWith(specificReviewName.toLowerCase())
    );
  }

  // if not enough specific reviews fill up with the general reviews
  const leftOverHomePageReviews = specificReviews.length < 3 ? 3 - specificReviews.length : 0;
  const reviews = specificReviews.slice(0, 3).concat(homePageReviews.slice(0, leftOverHomePageReviews));

  const createDescription = (description?: string, rating?: string, numberOfRatings?: number) => {
    if (!description || !rating || !numberOfRatings) {
      return "";
    }

    return description.replace("{rating}", rating).replace("{number}", numberOfRatings.toString());
  };

  return (
    <div className="reviews">
      <div className="reviews__container">
        <div className="reviews__intro">
          <div className="reviewcard__rating">
            <Stars stars={parse(kiyohRating.content?.general?.stars)} inList={false} />
          </div>
          <h3
            className="reviews__intro-heading"
            dangerouslySetInnerHTML={{
              __html: createDescription(
                parse(testimonials.content.general.description),
                parse(kiyohRating.content.general.rating),
                parse(kiyohRating.content.general.numberOfRatings)
              ),
            }}
          ></h3>
          <Link url={parse(kiyohRating.content.general.allReviewsUrl)} title="" className="reviews__intro-anchor">
            {testimonials.content.general.reviewLinkText}
          </Link>
        </div>

        <div className="reviews__cards">
          {reviews &&
            reviews.length &&
            reviews.map((review) => (
              <div className="reviewcard" key={review.id}>
                <div className="reviewcard__body">
                  <div className="reviewcard__text">
                    <div className="reviewcard__rating">
                      <Stars stars={parse(review.content?.general?.stars)} inList={false} />
                    </div>
                    <h4 className="reviewcard__heading">
                      {review.content?.general?.visitedClub
                        ? parse(review.content.general.visitedClub)
                        : parse(review.content?.general?.visitedEvent)}
                    </h4>
                    <p className="reviewcard__quote">{review.content?.general?.experienceOneLine}</p>
                    <h5 className="reviewcard__author">{review.content?.general?.name}</h5>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Reviews;
