import { first, get, isNil } from "lodash";
import React from "react";
import { Breadcrumb } from "../../components/breadcrumb";
import Hero from "../../components/hero";
import IconHeading from "../../components/iconheading";
import Intro from "../../components/intro/wildcard";
import Reviews from "../../components/reviews";
import Toggles from "../../components/toggles";
import TextAndGallery from "../../components/twocolumn/text-gallery";
import {
  TideItem,
  TideItemForCountry,
  TideItemForEvenementLocatie,
  TideItemForHeroComponent,
  TideItemForIntroComponent,
  TideItemForLocation,
  TideItemForNavigationSection,
  TideItemForOutroComponent,
  TideItemForOverviewComponent,
  TideItemForPageTitleComponent,
  TideItemForSporten,
  TideItemForTeam,
  TideItemForTestimonialsComponent,
  TideItemForUspFolder,
  TideItemForWebsite,
  TideItemForWildcardTeamPage,
} from "../../types";
import { getChildItemsInTypenameArray, getChildItemsWithTypename, getParentItemWithTypename, parse } from "../component";
import { Image } from "../../types";
import MapBlocksComponents, { BlockTypes } from "./map-blocks-components";
import { FilterProvider } from "../../contexts/filter-provider";
import FacetedSearch from "../../components/facetedsearch";
import PageTitle from "../../components/page-title";

interface MapTeamPageComponentsProps {
  website: TideItemForWebsite;
  page: TideItemForTeam;
  usps: TideItemForUspFolder;
  location: TideItemForLocation;
  breadcrumbs: Breadcrumb[];
  wildcardTeamPage: TideItemForWildcardTeamPage;
  country?: TideItemForCountry;
}

interface Toggle {
  id: string;
  text: string;
}

const MapTeamPageComponents: React.FC<MapTeamPageComponentsProps> = ({ website, page, usps, breadcrumbs, wildcardTeamPage, location, country }) => {
  const sport = getParentItemWithTypename("TideItemForSporten", parse(page)) as TideItemForSporten;
  const venues = getChildItemsWithTypename("TideItemForEvenementLocatie", sport) as TideItemForEvenementLocatie[];
  const venue = venues.find((v) => v.content?.general?.id === page.content?.general?.venueId);
  const teamInfoBlocks = getChildItemsInTypenameArray(BlockTypes, parse(page)) as any[];

  const componentRenderers = {
    TideItemForHeroComponent: (componentItem: TideItemForHeroComponent) => {
      const quickLinksSection = first(getChildItemsWithTypename("TideItemForNavigationSection", page) as TideItemForNavigationSection[]);
      return (
        <Hero
          key={componentItem.id}
          image={page.content?.general?.hero?.url ? parse(page.content?.general?.hero) : parse(country?.content?.general?.hero)}
          breadcrumbs={breadcrumbs}
          showQsm={parse(componentItem.content?.general?.showQsm)}
          qsmSlimVersion={true}
          extraClass="header--list-page"
          quickLinksSection={quickLinksSection}
        />
      );
    },
    TideItemForPageTitleComponent: (componentItem: TideItemForPageTitleComponent) => {
      return <PageTitle key={componentItem.id} title={parse(page.content?.general?.title)} image={parse(page.content?.general?.logo)} />;
    },
    TideItemForIntroComponent: (componentItem: TideItemForIntroComponent) => {
      return (
        <Intro
          key={componentItem.id}
          text={parse(page.content?.general?.intro)}
          // title={parse(page.content?.general?.title)}
          website={parse(website)}
          usps={parse(usps)}
          // image={parse(page.content?.general?.logo)}
        />
      );
    },
    TideItemForOutroComponent: (componentItem: TideItemForOutroComponent) => {
      const toggles = [] as Toggle[];
      toggles.push({ id: page.content?.outro?.title, text: page.content?.outro?.title } as Toggle);
      if (venue && venue.content?.general?.title)
        toggles.push({ id: venue?.content?.general?.title, text: venue?.content?.general?.title } as Toggle);
      if (location && location.content?.general?.title)
        toggles.push({ id: location.content?.general?.title, text: location.content?.general?.title });
      teamInfoBlocks.map((block) => toggles.push({ id: block.content?.general?.title, text: block.content?.general?.title } as Toggle));
      const images = parse(page.content?.outro?.gallery) as Image[];
      return (
        <div key={componentItem.id}>
          <IconHeading name={page.name} image={parse(page.content?.general?.logo)} />
          {toggles && toggles.length > 1 && <Toggles toggles={toggles} />}
          <TextAndGallery
            key={1}
            title={parse(page.content?.outro?.title)}
            text={parse(page.content?.outro?.text)}
            images={images}
            data={parse(page.content?.outro)}
            venueCapacity={parse(venue?.content?.general?.capacity)}
            anchorRef={parse(page.content?.outro?.title)}
            table={true}
          />
          {/* !!!!!!!!!Even uitzetten zodat de productie content niet dubbel komt te staan
          Dit moet niet naar staging!!!!!!!!!!!!!!!!! */}

          {/* <TextAndImage
            key={venue?.id}
            title={parse(venue?.content?.general?.title)}
            text={parse(venue?.content?.general?.text)}
            image={parse(venue?.content?.general?.image)}
            reverse={true}
            anchorRef={parse(venue?.content?.general?.title)}
          />
          {location && location.content?.general?.title && (
            <TextAndGallery
              key={3}
              title={parse(location.content?.general?.title)}
              text={parse(location.content?.general?.text)}
              images={parse(location.content?.general?.images) as Image[]}
              anchorRef={parse(location.content?.general?.title)}
              table={false}
            ></TextAndGallery>
          )} */}
          <MapBlocksComponents blocks={parse(teamInfoBlocks as TideItem[])} key={2} games={[]} />
        </div>
      );
    },
    TideItemForTestimonialsComponent: (componentItem: TideItemForTestimonialsComponent) => {
      return (
        <Reviews
          key={componentItem.id}
          testimonials={componentItem}
          specificReviewName={page.content?.kiyoh?.name ? `review ${page.content.kiyoh.name}` : undefined}
        />
      );
    },
    TideItemForOverviewComponent: (componentItem: TideItemForOverviewComponent) => {
      const type = first(componentItem.content?.general?.type);
      switch (type) {
        case "Packages":
          return (
            <FilterProvider key={componentItem.id}>
              <FacetedSearch page="team" teamName={parse(page.name)} itemsPerPage={6}></FacetedSearch>
            </FilterProvider>
          );
        default:
          return <></>;
      }
    },
  };

  return (
    <>
      {wildcardTeamPage?.childItems?.map((tideItem) => {
        const typeName = get(tideItem, "__typename");
        const renderer = get(componentRenderers, typeName);

        if (!isNil(renderer)) {
          return renderer(tideItem);
        }

        return null;
      })}
    </>
  );
};

export default MapTeamPageComponents;
