import React from "react";
import { Image } from "../../types";
import { parse } from "../../utils/component";
import { addWidthParameter } from "../../utils/image-utils";

interface IconHeadingProps {
  name?: string;
  image?: Image;
}

const IconHeading: React.FC<IconHeadingProps> = ({ name, image }) => {
  return (
    <div className="iconheading">
      <div className="iconheading__container">
        {image && <img src={addWidthParameter(parse(image?.url), 75)} className="iconheading__icon" alt={parse(image?.altText)} />}
        <h2 className="iconheading__text">{name}</h2>
      </div>
    </div>
  );
};

export default IconHeading;
