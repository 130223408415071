import { graphql } from "gatsby";
import React from "react";
import { Image } from "../../types";
import { parse } from "../../utils/component";
import { addWidthParameter } from "../../utils/image-utils";

interface PageTitleProps {
  title?: string;
  image?: Image;
  extraClassName?: string;
}

const PageTitle: React.FC<PageTitleProps> = ({ title, image }) => {
  return (
    <div className="page-title">
      <div className="page-title__container">
        <div className="page-title__text">
          <div className="heading-seperator heading-seperator--icon">
            <div className="iconheading">
              <div className="iconheading__container">
                <h1 className="iconheading__text">{title}</h1>
                {image && image.url && 
                  <div className="iconheading__icon__container">
                    <img src={addWidthParameter(parse(image?.url), 75)} className="iconheading__icon" alt={parse(image?.altText)} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;

export const query = graphql`
  fragment TideItemForPageTitleComponentFragment on TideItemForPageTitleComponent {
    __typename
    id
  }
`;
